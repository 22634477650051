const createAlert = (type, text) => {
  const template = `<div class="alert alert-${type} alert-dismissible fade show" role="alert">
    ${text}
  </div>`;

  $('body').append(template);

  $('.alert').not('.persistent').not('.alert-danger').delay(3000).fadeOut(3000);
}

module.exports = createAlert